import React from 'react'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { motion } from 'framer-motion';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export default function Services() {
  return (
    <motion.div
     initial={{opacity:0}}
     animate={{opacity:1}}
     exit={{opacity:0}}
     transition={{duration:1}}
    >
      <Box ml={2} mr={1.5} mb={5} mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={20}>
            <Item>
              <Typography mb={5}
                variant='h1'>Services
              </Typography>
              <Typography variant='h5' mt={5} mb={1} >
                Unlocking the Power of Retail Excellence with Logic Veda
              </Typography >
              <Typography noWrap={false} fontSize={16} mb={4} >
                Are you ready to take your retail business to new heights? Working with Logic Veda is your key to unlocking the full potential of online commerce and maximizing your sales on platforms like Amazon. Here's how the magic happens:
              </Typography>
              <Box display={'flex'} mb={20}>
                <Box m={3}>
                <Card sx={{ maxWidth: 355 }} >
                  <CardActionArea >
                    <CardMedia
                      component="img"
                      height="140"
                      image="/static/images/cards/photo-1458724338480-79bc7a8352e4.jpeg"
                      alt=""
                    />
                    <CardContent sx={{minHeight:260}}>
                      <Typography gutterBottom variant="h5" component="div">
                        Tailored Consultation
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        We believe in a personalized approach. Our team will conduct a thorough analysis of your business, identifying opportunities and challenges specific to your industry and market.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                </Box>

                <Box m={3}>
                <Card sx={{ maxWidth: 355 }} >
                  <CardActionArea >
                    <CardMedia
                      component="img"
                      height="140"
                      image="/static/images/cards/photo-1469719847081-4757697d117a.jpeg"
                      alt=""
                    />
                    <CardContent sx={{minHeight:260}}>
                      <Typography gutterBottom variant="h5" component="div">
                        Onboarding Made Easy
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Once we've mapped out a strategy, we'll guide you through the seamless onboarding process. From creating your seller account on Amazon to optimizing your product listings, we handle the technicalities so that you can focus on delivering quality products.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                </Box>

                <Box m={3}>  
                <Card sx={{ maxWidth: 355 }} >
                  <CardActionArea >
                    <CardMedia
                      component="img"
                      height="140"
                      image="/static/images/cards/photo-1492573637402-25691cd9eac2.jpeg"
                      alt=""
                    />
                    <CardContent sx={{minHeight:260}}>
                      <Typography gutterBottom variant="h5" component="div">
                        Strategic Growth Planning
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Logic Veda doesn't stop at onboarding. We work as your strategic partner, developing growth plans that align with your business objectives. Our experts leverage data-driven insights to optimize your online presence, drive traffic, and boost sales.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                </Box>

                <Box m={3}> 
                <Card sx={{ maxWidth: 355 }} >
                  <CardActionArea >
                    <CardMedia
                      component="img"
                      height="140"
                      image="/static/images/cards/photo-1622976479615-38700d470a1c.jpeg"
                      alt=""
                    />
                    <CardContent sx={{minHeight:260}}>
                      <Typography gutterBottom variant="h5" component="div">
                        Continuous Support
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        As your business evolves, so do our strategies. We provide ongoing support, keeping you informed about industry trends, platform updates, and opportunities to stay ahead of the competition.
                        Are you ready to amplify your sales and establish a formidable online presence? Partner with Logic Veda today, and let's write the success story of your retail journey together!
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                </Box>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
      {/*
    <div className="top" style={{backgroundColor:"#a39e9e"}}>
      <div className="card" style={{ backgroundUrl:'https://images.unsplash.com/photo-1492573637402-25691cd9eac2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',width: '90rem', height:'50vh' }}>
  
      </div>
      </div>
      */}
</motion.div>

    
  )
}
