import React from 'react'
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
    <footer className="container-fluid bg-grey px-5 pt-5 pb-2">
<div className="container">
   <div className="row">
      <div className="col-md-6">
         <div className="row">
            <div className="col-md-6 ">
               <div className="logo-part">
                  <h2 className="logo-footer">Logic Veda</h2>
                  {/* <p>7637 Laurel Dr. King Of Prussia, PA 19406</p> */}
                  <p>You Earn We Build</p>
               </div>
            </div>
            <div className="col-md-6 px-4">
               <h6> About Company</h6>
               <p>LoicVeda is a leading e-commerce solutions provider specializing in optimizing product listings and driving sales on online marketplaces</p>
               <a href="/services" className="btn-footer">  More Info </a><br/>
               <a href="https://wa.me/7000360531?text=I%27m%20interested%20in%20your%20service" className="btn-footer"> Contact Us</a>
            </div>
         </div>
      </div>
      <div className="col-md-6">
         <div className="row">
            <div className="col-md-6 px-4">
               <h6> Help us</h6>
               <div className="row ">
                  <div className="col-md-6">
                     <ul>
                        <li> <Link to="/"> Home</Link> </li>
                        {/* <li> <a href="#"> About</a> </li> */}
                        <li> <Link to="/services"> Services</Link> </li>
                        {/* <li> <a href="#"> Team</a> </li>
                        <li> <a href="#"> Help</a> </li> */}
                        <li> <Link to="https://wa.me/7000360531?text=I%27m%20interested%20in%20your%20service"> Contact Us</Link> </li>
                     </ul>
                  </div>
                  <div className="col-md-6 px-4">
                     <ul>
                        {/* <li> <a href="#"> Cab Faciliy</a> </li>
                        <li> <a href="#"> Fax</a> </li> */}
                        <li> <a href="#"> Terms</a> </li>
                        <li> <a href="#"> Policy</a> </li>
                        <li> <a href="#"> Refunds</a> </li>
                        {/* <li> <a href="#"> Paypal</a> </li> */}
                     </ul>
                  </div>
               </div>
            </div>
            {/* <div className="col-md-6 ">
               <h6> Newsletter</h6>
               <div className="social">
                  <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                  <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
               </div>
                <form className="form-footer my-3">
                  <input type="text"  placeholder="search here...." name="search">
                  <input type="button" value="Go" >
               </form> 
               <p>That's technology limitation of LCD monitors</p>
            </div> */}
         </div>
      </div>
   </div>
</div>
</footer>
</>
  )
}
