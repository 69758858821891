import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"

export default function Navbar() {
  return (
    <div id="abc">
      <nav>
        <ul style={{ marginBottom: "0" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          {/* <li>
            <Link to="/about">About Us</Link>
          </li> */}
          <li>
            <Link to="https://wa.me/7000360531?text=I%27m%20interested%20in%20your%20service">Contact Us</Link>
          </li>
        </ul>
        <ul style={{ float: "left", fontSize: "1.6vw", marginBottom: "0" }}>


          <motion.li className="animatable"
            initial={{ scale: 1.2 }}
            animate={{ scale: 1 }}
            transition={{duration:0.4}}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 }
            }}
            whileTap={{ scale: 0.9 }} >
            <li className="glow-on-hover" >
              <Link to={"/"} style={{ paddingBottom: "0" }}>
                <img height={30} src="logicveda-high-resolution-logo-white-transparent (3).png"></img>
              </Link>
            </li>
          </motion.li>

        </ul>
      </nav>
    </div>
  );
}
