import React from 'react'
import { Box } from '@mui/material'
// import { useScroll } from 'framer-motion'
// import { typography } from '@mui/system'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'

export default function Home() {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 770 })
  const isBigScreen = useMediaQuery({ minWidth: 770 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  const isRetina = useMediaQuery({ minResolution: '2dppx' })
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >


            <Box sx={{
                backgroundImage: "url('/photo-1502921935-be74c0dc50fd.jpeg')",
                backgroundRepeat: "no-repeat",
                WebkitBackgroundSize: "cover",
                height: '100vh',
                width: '100%'
            }}>
                <Box sx={{
                    display: 'inline-flex',
                    width: '35%'
                }}  >
                    <motion.typography initial={{opacity:0}} animate={{opacity:1}} transition={{delay:1 ,duration:2}} mt={2} style={{ color: 'white', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', marginTop: '25vh', marginLeft: '10vh' }}>Elevate your retail or B2B enterprise to new heights with our seamless e-commerce registration solutions.</motion.typography>
                </Box>

                <Box sx={{
                    display: 'inline-flex',
                    width: '63%'
                }}  >
                    <motion.typography initial={{opacity:0}} animate={{opacity:1}} transition={{delay:2.5 ,duration:2}} mt={2} style={{ color: 'white', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', marginTop: '25vh', marginLeft: '65vh' }}>Boost sales, broaden your reach, and thrive in the online marketplace effortlessly. Your success story starts here</motion.typography>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundImage: "url('/222-black-to-whitee.jpg')",
                    backgroundRepeat: "no-repeat",
                    height: '100vh',
                    width: '100%'
                }}>


            </Box>
            {/* 
            <div className="parent">
                <div>
                    <h1 style={{ fontSize: '2vw' }}>Are you a small or medium-sized retail business looking to thrive in the competitive world of e-commerce?</h1>
                    <br/>
                <p style={{ fontSize: '1.5vw' }}>Look no further! LogicVeda is your trusted partner in navigating the Amazon and Flipkart marketplace, ensuring your business not only survives but thrives in the face of industry giants.</p>
            </div>
        </div >
            <div className="parent">
                <div>
                    <h1 style={{fontSize: '5vw'}}>Keep Growing</h1>
                <br/>
                <p>Business opportunities are like buses, there's always another one coming</p>
            </div>
</div >

        <div className="parent">
            <div>
                <h1 style={{fontSize: '5vw'}}>Start Digitization</h1>
            <br/>
            <p>Play by the rules, but be ferocious</p>

        </div>
</div >

        <div className="parent">
            <div>
                <h1 style={{fontSize: '5vw'}}>Be the King</h1>
            <br/>
            <p>Success is not final; failure is not fatal: it is the courage to continue that counts.</p>

        </div>
</div >  */}

        </motion.div>
    )
}
