import React, { useState , useEffect} from 'react'
import { FormControl ,FormLabel, RadioGroup, FormControlLabel, Radio, InputLabel, Select, MenuItem, Grid, Paper, Typography, item} from '@mui/material';

const gridStyle = {
  root: {
    flexGrow: 1,
    height: '100vh',
  },
  leftPaper: {
    height: '66%',
    padding: '20px',
  },
  rightPaper: {
    height: '66%',
    padding: '20px',
    overflowY: 'auto', // Enable vertical scrolling
  },
};

export default function Calculator() {
    const [Category, setCategory] = useState('')
    const [subCategory, setsubCategory] = useState({})
    const [selectSubCategory, setSelectSubCategory] = useState('Automotive_Accessories')
    const [deliveryMode, setDeliveryMode] = useState('');
    const [closingFee, setClosingFee] = useState('')
    const [price, setPrice] = useState()
    const [weight, setWeight] = useState()
    const [length, setLength] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [inputlength, setinputLength] = useState()
    const [inputwidth, setinputWidth] = useState()
    const [inputheight, setinputHeight] = useState()
    const [inputPrice, setInputPrice] = useState('');
    const [deliveryService, setDeliveryService] = useState('');
    const [refferalFee, setRefferalFee] = useState('');
    const [weightType, setWeightType] = useState()
    const [girth, setGirth] = useState(0)
    const [stateFrom, setFromState] = useState('')
    const [stateTo, setToState] = useState('')
    const [regionTo, setToRegion] = useState()
    const [regionFrom, setFromRegion] = useState()
    const [regions, setRegions] = useState()
    const [stepLevel, setStepLevel] = useState()
    const [shippingFees, setshippingFees] = useState('')
    const [pickNPakcOtherFees, setpickNPakcOtherFees] = useState(0)
    const [fbaCubicFoot, setfbaCubicFoot] = useState('')
    
    useEffect (() => {
      calculateShippingFees({stepLevelFunc : stepLevel})
    },[stepLevel])

    const calculatePrice = (event) => {
      setInputPrice(event)
      setPrice(event.target.value);
      conditionalRefferalFee(selectSubCategory,event.target.value)
      
      if (deliveryMode == 'fba')
      {
        if (event.target.value <= 250) {
          setClosingFee(25);
      } else if (event.target.value <= 500) {
          setClosingFee(20);
      } else if (event.target.value <= 1000) {
          setClosingFee(18);
      } else {
          setClosingFee(40);
      }

      }   
      if (deliveryMode == 'easy_ship')
        {
          if(deliveryService == 'standard')
            {
                if (event.target.value <= 250) {
                  setClosingFee(3);
              } else if (event.target.value <= 500) {
                  setClosingFee(6);
              } else if (event.target.value <= 1000) {
                  setClosingFee(30);
              } else {
                  setClosingFee(56);
              }
              }
        if (deliveryService == 'prime') {
          if (event.target.value <= 250) {
            setClosingFee(8);
        } else if (event.target.value <= 500) {
            setClosingFee(12);
        } else if (event.target.value <= 1000) {
            setClosingFee(30);
        } else {
            setClosingFee(56);
        }
        }
      }

      if (deliveryMode == 'self_ship')
      {
        if (event.target.value <= 250) {
          setClosingFee(7);
      } else if (event.target.value <= 500) {
          setClosingFee(20);
      } else if (event.target.value <= 1000) {
          setClosingFee(36);
      } else {
          setClosingFee(65);
      }
    }
    };

    const calculateWeight = (event) => {
      setWeight(event.target.value);
      calculateShippingFees({ weightFunc : event.target.value })
      if (event.target.value>22.5)
      {
        setWeightType('Heavy_Bulky')
        if (deliveryMode == 'fba')
          {
            setpickNPakcOtherFees(26)
          } 
        calculateShippingFees({ weightTypeFunc : 'Heavy_Bulky' })
        
      }
      else if(girth<300)
      {
        setWeightType('Standard')
        if (deliveryMode == 'fba')
          {
            setpickNPakcOtherFees(14)
          } 
        calculateShippingFees({ weightTypeFunc : 'Standard' })
      }
    };

    const calculateLength = (event) => {
      setinputLength(event.target.value)
      setLength(event.target.value);
      if(event.target.value>183)
      {
        setWeightType('Heavy_Bulky')
        if (deliveryMode == 'fba')
          {
            setpickNPakcOtherFees(26)
          } 
        calculateShippingFees({ weightTypeFunc : 'Heavy_Bulky' })
      }
      calculateGirth(event.target.value,inputwidth,inputheight)
    };
    const calculateWidth = (event) => {
      setinputWidth(event.target.value)
      setWidth(event.target.value);
      if(event.target.value>183)
      {
        setWeightType('Heavy_Bulky')
        if (deliveryMode == 'fba')
          {
            setpickNPakcOtherFees(26)
          } 
          
        calculateShippingFees({ weightTypeFunc : 'Heavy_Bulky' })
      }
      calculateGirth(inputlength,event.target.value,inputheight)
    };
    const calculateHeight = (event) => {
      setinputHeight(event.target.value)
      setHeight(event.target.value);
      if(event.target.value>183)
      {
        setWeightType('Heavy_Bulky')
        if (deliveryMode == 'fba')
          {
            setpickNPakcOtherFees(26)
          } 
        calculateShippingFees({ weightTypeFunc : 'Heavy_Bulky' })
      }
      calculateGirth(inputlength,inputwidth,event.target.value)

    };

    const calculateGirth = (l,w,h) => {
      let price_per_cubic_foot=45
      setfbaCubicFoot(((l*w*h)/30480.7)*price_per_cubic_foot)
      setGirth((parseInt(l)+(2*(parseInt(w)+parseInt(h)))))
      if ((parseInt(l)+(2*(parseInt(w)+parseInt(h))))>300)
      {
        setWeightType('Heavy_Bulky')
        if (deliveryMode == 'fba')
          {
            setpickNPakcOtherFees(26)
          } 
        calculateShippingFees({ weightTypeFunc : 'Heavy_Bulky' })
      }
      else if(weight<22.5)
      {
        setWeightType('Standard')
        if (deliveryMode == 'fba')
          {
            setpickNPakcOtherFees(14)
          } 
        calculateShippingFees({ weightTypeFunc : 'Standard' })
      }
    };

    function conditionalRefferalFee(value,priceEvent)
    {
      
      let amount= price
      if (priceEvent)
      {
        amount=priceEvent
      }
      else{
        amount=price
      }
      if (subCategory[value][0]=='Helmets & Riding Gloves')
      {
        if (amount<=500)
        {
          setRefferalFee(6.5) 
        }
        else{
          setRefferalFee(7.5) 
        }
      } 
      else if (subCategory[value][0]=='Car and Bike parts')
      {
        if (amount<=500)
        {
          setRefferalFee(13) 
        }
        else if(amount>500){
          setRefferalFee(14) 
        }
      }   
      else if (subCategory[value][0]=='Cleaning kits & Car interior/exterior care')
      {
        if (amount<=500)
        {
          setRefferalFee(9) 
        }
        else if (amount>500 && amount<=1000)
        {
          setRefferalFee(10.5) 
        }
        else{
          setRefferalFee(12) 
        }
      } 
      else if (subCategory[value][0]=='Cleaning kits & Car interior/exterior care')
      {
        if (amount<=500)
        {
          setRefferalFee(9) 
        }
        else if (amount>500 && amount<=1000)
        {
          setRefferalFee(10.5) 
        }
        else{
          setRefferalFee(12) 
        }
      } 
      else if (subCategory[value][0]=='Automotive Accessories & Riding Gear')
      {
        if (amount<=1000)
        {
          setRefferalFee(14) 
        }
        else{
          setRefferalFee(15.5) 
        }
      }      
    }

    function categoryChange(event)
    {
        setCategory(event.target.value)
       if (event.target.value !== '')
       {
        setsubCategory(optionSubCategory[event.target.value])
       }
       else
       {
        setsubCategory()
       }
        
        
    }

    function subCategoryChange(event)
    {
      setSelectSubCategory(event.target.value) 
      
      if (subCategory[event.target.value].length>1)
      {
      setRefferalFee(subCategory[event.target.value][1]) 
      }
      else {
        conditionalRefferalFee(event.target.value)
      }
      
    }

    function handleDeliveryModeChange(event) {
      setDeliveryMode(event.target.value);
      calculateShippingFees({deliveryModeFunc: event.target.value})
      if(event.target.value!='fba')
        {
          setpickNPakcOtherFees(0)
          setfbaCubicFoot('')
        }
      if (inputPrice!==''){
        calculatePrice(inputPrice);
        }
    }

    function handleDeliveryServiceChange(event) {
      setDeliveryService(event.target.value);
      if (inputPrice!==''){
      calculatePrice(inputPrice);
      }
    }

    function handleStepLevelChange(event) {
      setStepLevel(event.target.value);
      calculateShippingFees({ stepLevelFunc : event.target.value })
    }

    function stateFromChange(event)
    {
        setFromState(event.target.value)
        setFromRegion(optionsState[event.target.value])
        regionToChange(optionsState[event.target.value],event.target.value,regionTo,stateTo)
    }

    function stateToChange(event)
    {
        setToState(event.target.value)
        setToRegion(optionsState[event.target.value])
        regionToChange(regionFrom,stateFrom,optionsState[event.target.value],event.target.value)

    }

    function regionToChange(from_Region,from_State,to_Region,to_State)
    {
      if(from_State=='' || to_State==''){
        setRegions()
      }  
      else if(from_State==to_State)
        {
          setRegions('Local')
          calculateShippingFees({ regionsFunc : 'Local' })
        }
        else if(from_Region==to_Region)
        {
          setRegions('Regional')
          calculateShippingFees({ regionsFunc : 'Regional' })
        }
        else{
          setRegions('National')
          calculateShippingFees({ regionsFunc : 'National' })
        }

    }

    function calculateShippingFees({ stepLevelFunc = null, regionsFunc = null, weightFunc = null, weightTypeFunc = null , deliveryModeFunc = null} = {})
    {
      
      if(stepLevel!=null && regions!=null && weight!=null && weightType!=null && deliveryMode!=null){
        if (stepLevelFunc == null)
          {
            stepLevelFunc = stepLevel
          }
        if (regionsFunc == null)
          {
            regionsFunc = regions
          }
        if (weightFunc == null)
          {
            weightFunc = weight
          }  
        if (weightTypeFunc == null)
          {
            weightTypeFunc = weightType
          }  
        if (deliveryModeFunc == null)
          {
            deliveryModeFunc = deliveryMode
          }     
        if(weightTypeFunc=='Heavy_Bulky')
          {
            if (weightFunc>12)
              {
                let weight_multiple = weightFunc-12
                setshippingFees(weightHandlingCharges[weightTypeFunc][deliveryModeFunc]['after_12kg'][regionsFunc] * weight_multiple + weightHandlingCharges[weightTypeFunc][deliveryModeFunc]['first_12kg'][regionsFunc])
              }
              else{
                setshippingFees(weightHandlingCharges[weightTypeFunc][deliveryModeFunc]['first_12kg'][regionsFunc]) 
              }
          }
          else{
            let weight_multiple = weightFunc-0.5
            let per_kg_extra = 0
            switch (regionsFunc)
            {
              case 'Local': per_kg_extra=16
                            break;
              case 'Regional': per_kg_extra=21
                            break;
              case 'National': per_kg_extra=26
                            break;                     
            }
            
            setshippingFees(weightHandlingCharges[weightTypeFunc][deliveryModeFunc][stepLevelFunc][regionsFunc] + (per_kg_extra*weight_multiple))
          }
        } 
    }

    
      
    

    const optionsCategory = {
        Automotive_Accessories: 'Automotive, Car & Accessories',
        Baby_Products_Toys_Education :'Baby Products, Toys & Education', 
        Books_Music_Movies_Video_Games_Entertainment:'Books, Music, Movies, Video Games, Entertainment',
        Industrial_Medical_Scientific_Supplies_Office_Products:'Industrial, Medical, Scientific Supplies & Office Products',
        Clothing_Fashion_Fashion_Accessories_Jewellery_Luggage_Shoes: 'Clothing, Fashion, Fashion Accessories, Jewellery, Luggage, Shoes',
        Electronics_Camera_Mobile_PC_Wireless_Accessories: 'Electronics (Camera, Mobile, PC, Wireless) & Accessories',
        Grocery_Food_Pet_Supplies:'Grocery, Food & Pet Supplies',
        Health_Beauty_Personal_Care_Personal_Care_Appliances: 'Health, Beauty, Personal Care & Personal Care Appliances',
        Home_Décor_Home_Improvement_Furniture_Outdoor_Lawn_Garden: 'Home, Décor, Home Improvement, Furniture, Outdoor, Lawn & Garden',
        Kitchen_Large_Small_Appliances: 'Kitchen, Large & Small Appliances',
        Sports_Gym_Sporting_Equipment: 'Sports, Gym & Sporting Equipment',
        Others:'Others',
    }

    const optionSubCategory = {
      Automotive_Accessories :  {
                                Helmets_Riding_Gloves: ["Helmets & Riding Gloves"],
                                Tyres_Rims: ["Tyres & Rims",5],
                                Automotive_Vehicles: ["Automotive Vehicles",2],
                                Car_and_Bike_parts: ["Car and Bike parts"],
                                Cleaning_kits_Car_interior_exterior_care : ["Cleaning kits & Car interior/exterior care"],
                                Automotive_Accessories_Riding_Gear: ["Automotive Accessories & Riding Gear"],
                                Vehicle_Tools_and_Appliances: ["Vehicle Tools and Appliances"],
                                Oils_Lubricants: ["Oils, Lubricants",8.5],
                                Batteries_and_air_fresheners: ["Batteries and air fresheners",6.5],
                                Car_Electronics_Devices: ["Car Electronics Devices",7.5],
                                Car_Electronics_Accessories: ["Car Electronics Accessories",10.5]
                                },
      Baby_Products_Toys_Education : {
                                  Baby_Hardlines_Swings_Bouncers_and_Rockers_Carriers_Walkers: ["Baby Hardlines - Swings, Bouncers and Rockers, Carriers, Walkers"],
                                  Baby_Safety_Guards_Locks: ["Baby Safety - Guards & Locks"],
                                  Baby_Room_Décor: ["Baby Room Décor"],
                                  Baby_Furniture: ["Baby Furniture"],
                                  Baby_Car_Seats_Accessories: ["Baby Car Seats & Accessories"],
                                  appa_Strollers_Buggies_Prams: ["appa Strollers, Buggies & Prams"],
                                  Baby_diapers: ["Baby diapers"],
                                  Toys_Drones: ["Toys - Drones",11],
                                  Toys_Balloons_and_Soft_Toys: ["Toys - Balloons and Soft Toys",11]
                                  },

Books_Music_Movies_Video_Games_Entertainment : 
                                  {
                                    Books: ["Books"],
                                    Movies: ["Movies",6.5],
                                    Music: ["Music",6.5],
                                    Musical_Instruments_xcluding_Guitars_and_Keyboards: ["Musical Instruments (excluding Guitars and Keyboards)",7.5],
                                    Musical_Instruments_Guitars : ["Musical Instruments - Guitars"],
                                    Musical_Instruments_Keyboards: ["Musical Instruments - Keyboards"],
                                    Musical_Instruments_DJ_VJ_Equipment_Recording_and_Computer_Cables_Leads_Microphones_PA_Stage : ["Musical Instruments - DJ & VJ Equipment, Recording and Computer, Cables & Leads, Microphones, PA & Stage"],
                                    Video_Games_Online_game_services: ["Video Games - Online game services"],
                                    Video_Games_Accessories: ["Video Games - Accessories"],
                                    Video_Games_Consoles: ["Video Games - Consoles"],
                                    Video_Games: ["Video Games"]
                                  }, 
                                  Industrial_Medical_Scientific_Supplies_Office_Products  : 
                                  {
                                    Business_and_Industrial_Supplies_Scientific_Supplies: ["Business and Industrial Supplies - Scientific Supplies"],
                                    Janitorial_Sanitation_Cleaners_deodorisers_Mops_buckets_tissues_wipes_commercial_vacuum_cleaners_dispensers_etc_Medical_and_Healthcare_supplies: ["Janitorial & Sanitation (Cleaners & deodorisers, Mops/buckets, tissues & wipes, commercial vacuum cleaners, dispensers etc.), Medical and Healthcare supplies",5.5],
                                    OTC_Medicine: ["OTC Medicine"],
                                    Masks: ["Masks",7],
                                    Weighing_Scales_Fat_Analyzers: ["Weighing Scales & Fat Analyzers"],
                                    Three_D_Printers : ["3D Printers",7],
                                    Business_and_Industrial_Supplies_Material_Handling_Equipment_Janitorial_Sanitation_Medical_Dental_Supplies_Commercial_kitchen_and_refrigeration_Equipment: ["Business and Industrial Supplies - Material Handling Equipment, Janitorial & Sanitation, Medical & Dental Supplies, Commercial kitchen and refrigeration Equipment",5.5],
                                    Business_and_Industrial_Supplies_Electrical_Testing_Dimensional_Measurement_Thermal_Printers_Barcode_Scanners: ["Business and Industrial Supplies - Electrical Testing, Dimensional Measurement, Thermal Printers, Barcode Scanners",5],
                                    Business_and_Industrial_Supplies_Power_tools_accessories_Welding_machines_Microscopes_Industrial_Electrical_products: ["Business and Industrial Supplies -Power tools & accessories, Welding machines, Microscopes, Industrial Electrical products",9],
                                    Occupational_Safety_Supplies_Mask_gloves_Safety_shoes_Face_shields_other_PPE_products: ["Occupational Safety Supplies (Mask, gloves, Safety shoes, Face shields & other PPE products)",5],
                                    Stethoscopes: ["Stethoscopes",7.5],
                                    Packing_materials: ["Packing materials",5],
                                    Power_hand_Tools_and_Water_Dispenser : ["Power & hand Tools and Water Dispenser",9],
                                    Office_products_Office_supplies_stationary_Paper_products_Art_and_craft_supplies_Pens_Pencils_Writing_Supplies: ["Office products - Office supplies, stationary, Paper products, Art and craft supplies, Pens, Pencils & Writing Supplies",8]
                                  },                     
Clothing_Fashion_Fashion_Accessories_Jewellery_Luggage_Shoes : 
                                            {
                                              Apparel_Accessories: ["Apparel - Accessories"],
                                              Apparel_Sweat_Shirts_and_Jackets: ["Apparel - Sweat Shirts and Jackets"],
                                              Apparel_Shorts: ["Apparel - Shorts"],
                                              Apparel_Baby: ["Apparel - Baby"],
                                              Apparel_Ethnic_wear: ["Apparel - Ethnic wear"],
                                              Apparel_Other_innerwear: ["Apparel - Other innerwear"],
                                              Apparel_Sleepwear: ["Apparel - Sleepwear"],
                                              Apparel_Sarees_and_Dress_Materials: ["Apparel - Sarees and Dress Materials"],
                                              Apparel_Mens_Tshirts_except_Polos_Tank_tops_and_full_sleeve_tops: ["Apparel - Men's T-shirts (except Polos, Tank tops and full sleeve tops)"],
                                              Apparel_Womens_Innerwear_Lingerie: ["Apparel - Womens' Innerwear / Lingerie"],
                                              Backpacks: ["Backpacks"],
                                              Eyewear_Sunglasses_Frames_and_zero_power_eye_glasses: ["Eyewear - Sunglasses, Frames and zero power eye glasses"],
                                              Fashion_Jewellery: ["Fashion Jewellery"],
                                              Fine_Jewellery_Gold_Coins: ["Fine Jewellery - Gold Coins",2.5],
                                              Fine_Jewellery_studded: ["Fine Jewellery - studded",10],
                                              Fine_Jewellery_unstudded_and_solitaire: ["Fine Jewellery - unstudded and solitaire",5],
                                              Silver_Jewellery: ["Silver Jewellery"],
                                              Flip_Flops_Fashion_Sandals_and_Slippers: ["Flip Flops, Fashion Sandals and Slippers"],
                                              Handbags: ["Handbags"],
                                              Luggage_Suitcase_Trolleys: ["Luggage - Suitcase & Trolleys"],
                                              Luggage_Travel_Accessories: ["Luggage - Travel Accessories"],
                                              Kids_shoes: ["Kids shoes"],
                                              Shoes: ["Shoes"],
                                              Shoes_Sandals_Floaters: ["Shoes - Sandals & Floaters",10.5],
                                              Wallets: ["Wallets"],
                                              Watches: ["Watches",13.5]
                                            },

                                            Electronics_Camera_Mobile_PC_Wireless_Accessories: 
                                            {
                                              Cables_Electronics_PC_Wireless: ["Cables - Electronics, PC, Wireless",20],
                                              Camera_Accessories: ["Camera Accessories",11],
                                              Camera_Lenses: ["Camera Lenses",7],
                                              Camera_and_Camcorder: ["Camera and Camcorder",5],
                                              Cases_Covers_Skins_Screen_Guards: ["Cases, Covers, Skins, Screen Guards"],
                                              Desktops: ["Desktops",8],
                                              Electronic_Accessories_Electronics_PC_Wireless: ["Electronic Accessories (Electronics, PC & Wireless)",17],
                                              Electronic_Devices_except_TV_Camera_Camcorder_Camera_Lenses_and_Accessories_GPS_Devices_Speakers: ["Electronic Devices (except TV, Camera & Camcorder, Camera Lenses and Accessories, GPS Devices, Speakers)",9],
                                              Entertainment_Collectibles: ["Entertainment Collectibles"],
                                              Fashion_Smartwatches: ["Fashion Smartwatches",15.5],
                                              GPS_Devices: ["GPS Devices",13.5],
                                              Hard_Disks: ["Hard Disks",9.5],
                                              Headsets_Headphones_and_Earphones: ["Headsets, Headphones and Earphones",18],
                                              Keyboards_and_Mouse: ["Keyboards and Mouse",14],
                                              Kindle_Accessories: ["Kindle Accessories",25],
                                              Laptop_Bags_Sleeves: ["Laptop Bags & Sleeves"],
                                              Laptop_and_Camera_Battery: ["Laptop and Camera Battery",13],
                                              Laptops: ["Laptops"],
                                              Memory_Cards: ["Memory Cards",13],
                                              Mobile_phones: ["Mobile phones",5],
                                              Tablets_including_Graphic_Tablets: ["Tablets (including Graphic Tablets)",6],
                                              Modems_Networking_Devices: ["Modems & Networking Devices",14],
                                              Monitors: ["Monitors",6.5],
                                              PC_Components_RAM_Motherboards: ["PC Components (RAM, Motherboards)",5.5],
                                              Power_Banks_Chargers: ["Power Banks & Chargers",20],
                                              Printers_Scanners: ["Printers & Scanners",9],
                                              Software_Products: ["Software Products",9.5],
                                              Speakers: ["Speakers",11],
                                              Television: ["Television",6],
                                              Landline_Phones: ["Landline Phones",7],
                                              Smart_Watches_Accessories: ["Smart Watches & Accessories",15.5],
                                              USB_Flash_Drives_Pen_Drives: ["USB Flash Drives (Pen Drives)",16],
                                              Projectors_Home_Theatre_Systems_Binoculars_and_Telescopes: ["Projectors, Home Theatre Systems, Binoculars and Telescopes",6]
                                            },
Grocery_Food_Pet_Supplies :
                                            {
                                              Grocery_herbs_and_spices: ["Grocery - herbs and spices"],
                                              Grocery_Dried_fruits_and_nuts: ["Grocery - Dried fruits and nuts"],
                                              Grocery_Hampers_and_gifting: ["Grocery - Hampers and gifting"],
                                              Pet_food: ["Pet food"],
                                              Pet_Products: ["Pet Products"]
                                            },
Health_Beauty_Personal_Care_Personal_Care_Appliances: 
                                            {
                                              Beauty_Fragrance: ["Beauty - Fragrance",14],
                                              Beauty_Haircare_Bath_and_Shower: ["Beauty - Haircare, Bath and Shower"],
                                              Beauty_Makeup: ["Beauty - Makeup",6],
                                              Beauty_Products: ["Beauty Products"],
                                              Deodorants: ["Deodorants",6.50],
                                              Facial_steamers: ["Facial steamers",7],
                                              Prescription_Medicine: ["Prescription Medicine",6],
                                              Health_Personal_Care_HPC_Medical_Equipment_Contact_Lens: ["Health & Personal Care (HPC) - Medical Equipment & Contact Lens",8],
                                              Health_and_Personal_Care_Ayurvedic_products_Oral_care_hand_sanitizers_Pooja_supplies: ["Health and Personal Care - Ayurvedic products, Oral care, hand sanitizers, Pooja supplies"],
                                              Health_Personal_Care_HPC_Nutrition: ["Health & Personal Care (HPC) - Nutrition",9],
                                              Health_and_Personal_Care_Contact_lens_and_reading_glasses: ["Health and Personal Care - Contact lens and reading glasses",12],
                                              Luxury_Beauty: ["Luxury Beauty",5],
                                              Car_Cradles_Lens_Kits_and_Tablet_Cases: ["Car Cradles, Lens Kits and Tablet Cases"],
                                              Personal_Care_Appliances_Electric_Massagers: ["Personal Care Appliances - Electric Massagers"],
                                              Personal_Care_Appliances_Grooming_Styling: ["Personal Care Appliances (Grooming & Styling)"],
                                              Personal_Care_Appliances_Glucometer_and_Glucometer_Strips: ["Personal Care Appliances - Glucometer and Glucometer Strips",5.5],
                                              Personal_Care_Appliances_Thermometers: ["Personal Care Appliances - Thermometers"],
                                              Personal_Care_Appliances_Weighing_Scales_and_Fat_Analyzers: ["Personal Care Appliances - Weighing Scales and Fat Analyzers"]
                                          },
Home_Décor_Home_Improvement_Furniture_Outdoor_Lawn_Garden:
                                            {
                                              Bean_Bags_Inflatables: ["Bean Bags & Inflatables",11],
                                              Mattresses: ["Mattresses"],
                                              Rugs_and_Doormats: ["Rugs and Doormats",10.5],
                                              Clocks: ["Clocks",10],
                                              Wall_Art: ["Wall Art"],
                                              Home_Fragrance_Candles: ["Home - Fragrance & Candles"],
                                              Bedsheets_Blankets_and_covers: ["Bedsheets, Blankets and covers"],
                                              Home_furnishing_Excluding_curtain_and_curtain_accessories: ["Home furnishing (Excluding curtain and curtain accessories)",11],
                                              Containers_Boxes_Bottles_and_Kitchen_Storage: ["Containers, Boxes, Bottles, and Kitchen Storage"],
                                              Home_improvement_Accessories: ["Home improvement - Accessories",13.5],
                                              Home_improvement_excl_accessories_including_Home_Security_Systems: ["Home improvement (excl. accessories), including Home Security Systems",9],
                                              Ladders_Kitchen_and_Bath_fixtures: ["Ladders, Kitchen and Bath fixtures",8],
                                              Home_Storage_Excluding_Kitchen_Containers_Boxes_Bottles_and_Kitchen_Storage: ["Home Storage (Excluding Kitchen Containers, Boxes, Bottles, and Kitchen Storage)"],
                                              Wallpapers_Wallpaper_Accessories: ["Wallpapers & Wallpaper Accessories",13],
                                              Home_Decor_Products: ["Home Decor Products"],
                                              Wall_Paints_and_Tools: ["Wall Paints and Tools",6],
                                              Home_Waste_Recycling: ["Home - Waste & Recycling",8],
                                              Craft_materials: ["Craft materials"],
                                              Water_Purifier_and_Accessories: ["Water Purifier and Accessories"],
                                              Water_Heaters_and_Accessories: ["Water Heaters and Accessories"],
                                              Home_improvement_Kitchen_Bath_Cleaning_Supplies_Paints_Electricals_Hardware_Building_Materials: ["Home improvement - Kitchen & Bath, Cleaning Supplies, Paints, Electricals, Hardware, Building Materials",9],
                                              Home_Safety_Security_Systems: ["Home Safety & Security Systems",6],
                                              Inverter_and_Batteries: ["Inverter and Batteries"],
                                              Cleaning_and_Home_Appliances: ["Cleaning and Home Appliances"],
                                              Ladders: ["Ladders",7],
                                              Indoor_Lighting_Wall_ceiling_fixture_lights_lamp_bases_lamp_shades_and_Smart_Lighting: ["Indoor Lighting Wall, ceiling fixture lights, lamp bases, lamp shades and Smart Lighting",14.5],
                                              LED_Bulbs_and_Battens: ["LED Bulbs and Battens"],
                                              Cushion_Covers: ["Cushion Covers"],
                                              Curtains_and_Curtain_Accessories: ["Curtains and Curtain Accessories",15.5],
                                              Slipcovers_and_Kitchen_Linens: ["Slipcovers and Kitchen Linens",14.50],
                                              Safes_and_Lockers_with_Locking_Mechanism: ["Safes and Lockers with Locking Mechanism",12],
                                              Lawn_Garden_Solar_Panels: ["Lawn & Garden - Solar Panels",6],
                                              Lawn_Garden_Leaf_blower_and_Water_pump: ["Lawn & Garden - Leaf blower and Water pump",6.5],
                                              Lawn_Garden_Commercial_Agricultural_Products: ["Lawn & Garden - Commercial Agricultural Products",3],
                                              Lawn_Garden_Chemical_Pest_Control_Mosquito_nets_Bird_control_Plant_protection_Foggers: ["Lawn & Garden- Chemical Pest Control, Mosquito nets, Bird control, Plant protection, Foggers"],
                                              Lawn_Garden_Solar_Devices_Panels_Inverters_Charge_controller_Battery_Lights_Solar_gadgets: ["Lawn & Garden - Solar Devices (Panels, Inverters, Charge controller, Battery, Lights, Solar gadgets)",8],
                                              Lawn_and_Garden_Planters_Fertilisers_Watering_and_other_subcategories: ["Lawn and Garden - Planters, Fertilisers, Watering and other subcategories"],
                                              Lawn_and_Garden_Plants_Seeds_Bulbs: ["Lawn and Garden - Plants, Seeds & Bulbs"],
                                              Lawn_Garden_Outdoor_equipments_Saws_Lawn_Mowers_Cultivator_Tiller_String_Trimmers_Water_Pumps_Generators_Barbeque_Grills_Greenhouses: ["Lawn & Garden - Outdoor equipments (Saws, Lawn Mowers, Cultivator, Tiller, String Trimmers, Water Pumps, Generators, Barbeque Grills, Greenhouses)",5.5]
                                          }, 
Kitchen_Large_Small_Appliances: 
                                            {
                                              Kitchen_Non_Appliances: ["Kitchen- Non Appliances"],
                                              Kitchen_Glassware_Ceramicware: ["Kitchen - Glassware & Ceramicware"],
                                              Kitchen_Gas_Stoves_Pressure_Cookers: ["Kitchen - Gas Stoves & Pressure Cookers"],
                                              Cookware_Tableware_Dinnerware: ["Cookware, Tableware & Dinnerware"],
                                              Kitchen_Tools_Supplies_Choppers_Knives_Bakeware_Accessories: ["Kitchen Tools & Supplies - Choppers, Knives, Bakeware & Accessories"],
                                              Kitchen_Bath_fixtures_Cleaning_Supplies_Hardware_Electricals_and_Building_Materials: ["Kitchen & Bath fixtures, Cleaning Supplies, Hardware, Electricals and Building Materials"],
                                              Large_Appliances_excl_Accessories_Refrigerators_and_Chimneys: ["Large Appliances (excl. Accessories, Refrigerators and Chimneys)",5.5],
                                              Large_Appliances_Accessories: ["Large Appliances - Accessories",16],
                                              Large_Appliances_Chimneys: ["Large Appliances - Chimneys",7.5],
                                              Large_Appliances_Refrigerators: ["Large Appliances - Refrigerators",5],
                                              Small_Appliances: ["Small Appliances"],
                                              Fans_and_Robotic_Vacuums: ["Fans and Robotic Vacuums"]
                                          },

Sports_Gym_Sporting_Equipment: 
                                            {
                                              Bicycles: ["Bicycles",6],
                                              Gym_Equipments: ["Gym Equipments"],
                                              Sports_Cricket_and_Badminton_Equipments_Tennis_Table_Tennis_Squash_Football_Volleyball_Basketball_Throwball_Swimming: ["Sports- Cricket and Badminton Equipments, Tennis, Table Tennis, Squash, Football, Volleyball, Basketball, Throwball, Swimming"],
                                              Sports_Collectibles: ["Sports Collectibles"],
                                              Sports_Outdoors_Footwear: ["Sports & Outdoors - Footwear"]
                                          },

Others:
                                            {
                                              Coin_Collectibles: ["Coin Collectibles",15],
                                              Silver_Coins_Bars: ["Silver Coins & Bars",2.5],
                                              Furniture_Other_products: ["Furniture - Other products"],
                                              Toys_Other_Products: ["Toys - Other Products"],
                                              Grocery_Other_Products: ["Grocery - Other Products"],
                                              Office_Other_products: ["Office - Other products"],
                                              Personal_Care_Appliances_Other_Products: ["Personal Care Appliances - Other Products",7.5],
                                              Health_Personal_Care_HPC_Other_Subcategories: ["Health & Personal Care (HPC) - Other Subcategories",11],
                                              Health_and_Personal_Care_Other_Household_Supplies: ["Health and Personal Care - Other Household Supplies"],
                                              Business_Industrial_Supplies_Other_Products: ["Business & Industrial Supplies - Other Products"],
                                              Home_Other_Subcategories: ["Home - Other Subcategories",17],
                                              Lawn_and_Garden_Other_products: ["Lawn and Garden - Other products"],
                                              Luggage_Other_products: ["Luggage - Other products"],
                                              Fine_Art: ["Fine Art",20],
                                              Baby_Products_Other_Products: ["Baby Products - Other Products"],
                                              Apparel_Other_Products: ["Apparel - Other Products"],
                                              Home_other_products: ["Home - other products",17],
                                              Indoor_Lighting_Others: ["Indoor Lighting - Others"],
                                              Sports_Other_products: ["Sports - Other products"],
                                              Automotive_Other_products: ["Automotive - Other products"],
                                              Consumable_Physical_Gift_Card: ["Consumable Physical Gift Card",5],
                                              Warranty_Services: ["Warranty Services",25]
                                          },   
                                  
      } 
      const optionsState = {
        "Andaman and Nicobar": 3,
        "Andhra Pradesh": 3,
        "Arunachal Pradesh": 4,
        "Assam": 4,
        "Bihar": 4,
        "Chandigarh": 1,
        "Chhattisgarh": 4,
        "Dadar and Nagar": 2,
        "Delhi": 1,
        "Diu and Daman": 2,
        "Goa": 3,
        "Gujarat": 2,
        "Haryana": 1,
        "Himachal Pradesh": 1,
        "Jammu and Kashmir": 1,
        "Jharkhand": 4,
        "Karnataka": 3,
        "Kerala": 3,
        "Lakshadweep": 3,
        "Madhya Pradesh": 2,
        "Maharashtra": 2,
        "Manipur": 4,
        "Meghalaya": 4,
        "Mizoram": 4,
        "Nagaland": 4,
        "Odisha": 4,
        "Pondicherry": 3,
        "Punjab": 1,
        "Rajasthan": 1,
        "Sikkim": 4,
        "Tamil Nadu": 3,
        "Telangana": 3,
        "Tripura": 4,
        "Uttar Pradesh - Zone A": 1,
        "Uttar Pradesh-Zone B": 4,
        "Uttarakhand": 1,
        "West Bengal": 4
      }
  const weightHandlingCharges = {

    Standard:
                    {
                      easy_ship:
                                  {

                                    Basic: {
                                      Local: 53,
                                      Regional: 60.5,
                                      National: 81
                                    },
                                    Standard: {
                                      Local: 47,
                                      Regional: 56,
                                      National: 77
                                    },
                                    Advanced: {
                                      Local: 41,
                                      Regional: 54.5,
                                      National: 76
                                    },
                                    Premium: {
                                      Local: 41,
                                      Regional: 54.5,
                                      National: 76
                                    },
                                  },
                      fba:
                                  {
                                    Basic: {
                                      Local: 39,
                                      Regional: 46.5,
                                      National: 67,
                                      IXD: 50
                                    },
                                    Standard: {
                                      Local: 33,
                                      Regional: 42,
                                      National: 63,
                                      IXD: 46
                                    },
                                    Advanced: {
                                      Local: 27,
                                      Regional: 40.5,
                                      National: 62,
                                      IXD: 45
                                    },
                                    Premium: {
                                      Local: 27,
                                      Regional: 40.5,
                                      National: 62,
                                      IXD: 45
                                    },
                                  },
                    },

    Heavy_Bulky: {

                    easy_ship:
                              {
                                    first_12kg: {
                                      Local: 192,
                                      Regional: 277,
                                      National: 371
                                    },
                                    after_12kg: {
                                      Local: 5,
                                      Regional: 6,
                                      National: 12
                                    },
                              },
                      fba:      
                                  {
                                    first_12kg: {
                                      Local: 88,
                                      Regional: 130.5,
                                      National: 177.5
                                    },
                                    after_12kg: {
                                      Local: 2.5,
                                      Regional: 3,
                                      National: 6
                                    },
                                  },
                    }

  } 
  
  return (
    <>

<Grid container spacing={2} style={gridStyle.root}>
      {/* Left Partition */}
      <Grid item xs={4}>
        <Paper style={gridStyle.leftPaper}>
          <Typography variant="h6">Total Fees
          {refferalFee!='' && closingFee!='' && shippingFees!='' ? ( <p>{((refferalFee*price)/100)+closingFee+shippingFees+pickNPakcOtherFees}</p> ) : null}
          </Typography>
          <br />  
        <p>Refferal Fee %: {refferalFee}</p>
        <p>Closing Fee : {closingFee}</p> 
        <p>Weight : {weight}</p>
        <p>Weight Type: {weightType}</p>
        <p>Region From: {regionFrom}</p>
        <p>Region To: {regionTo}</p>
        <p>Type of shipment: {regions}</p>
        <p>Step Level: {stepLevel}</p>
        <p>Delivery Mode: {deliveryMode}</p>
        <p>shipping Fees: {shippingFees}</p>
        {pickNPakcOtherFees!=0? <p>Pick & Pack Fee (FBA Only) Fees: {pickNPakcOtherFees}</p> : null}
        {fbaCubicFoot!=''? <p>Per cubic foot per month (FBA Only) Fees: {fbaCubicFoot}</p> : null}
        
        </Paper>
      </Grid>
      {/* Right Partition */}
      <Grid item xs={8}>
        <Paper style={gridStyle.rightPaper}>
          <Typography variant="h6">Calculator</Typography>
          {/* Content for the right partition */}
          {/* Example long content for demonstration */}

            <Grid container spacing={2}>
              <Grid item xs={8}>
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Product Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Category}
                label="Product Category"
                onChange={categoryChange}
              >
                {Object.keys(optionsCategory).map((key) => (
                <MenuItem value={key}>{optionsCategory[key]}</MenuItem>
              
                ))}
              </Select>
            </FormControl>
              </Grid>
              <Grid item xs={4}>
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Product Sub Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectSubCategory}
                label="Sub Product Category"
                onChange={subCategoryChange}
              >
                {Object.keys(subCategory).map((key) => (
                <MenuItem value={key}>{subCategory[key][0]}</MenuItem>
              
                ))}
              </Select>
            </FormControl>
              </Grid>
              <Grid item xs={5}>
              <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Delivery Modes</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={deliveryMode}
                onChange={handleDeliveryModeChange}
              >
                <FormControlLabel value="fba" control={<Radio />} label="Fullfilled By Amazon (FBA)" />
                <FormControlLabel value="easy_ship" control={<Radio />} label="Easy Ship" />
                <FormControlLabel value="self_ship" control={<Radio />} label="Self Ship" />
              </RadioGroup>
            </FormControl>
              </Grid>
              <Grid item xs={4}>
              { deliveryMode == 'easy_ship' && (
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Delivery Service</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={deliveryService}
                  onChange={handleDeliveryServiceChange}
                >
                  <FormControlLabel value="standard" control={<Radio />} label="Standard Easy Ship" />
                  <FormControlLabel value="prime" control={<Radio />} label="Easy Ship Prime only" />
                </RadioGroup>
              </FormControl>
              
          )}
              </Grid>

              <Grid item xs={4}>
              <div>
          <h6>Product Amount</h6>
            <input
                type="number"
                placeholder="Enter price"
                value={price}
                onChange={calculatePrice}
            />
        </div>
              </Grid>

              <Grid item xs={4}>
              <div>
        <h6 style={{marginTop:'1vh'}}>Product Weight (Kgs)</h6>
            <input
                type="number"
                placeholder="Enter Weight in Kgs"
                value={weight}
                onChange={calculateWeight}
            />
        </div>
              </Grid>

              <Grid item xs={4}>
              <h6 style={{marginTop:'1vh'}}>Product Dimensions  (Cms)</h6>
        <p>Length: </p>
            <input
                type="number"
                placeholder="Enter Length in Cms"
                value={length}
                onChange={calculateLength}
            />
            <p>Width: </p>
            <input
                type="number"
                placeholder="Enter Width in Cms"
                value={width}
                onChange={calculateWidth}
            />
            <p>Height: </p>
            <input
                type="number"
                placeholder="Enter Height in Cms"
                value={height}
                onChange={calculateHeight}
            />
              </Grid>

              <Grid item xs={6}>
              <p>Delivery From</p>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Delivery From</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={stateFrom}
            label="Product Category"
            onChange={stateFromChange}
          >
            {Object.keys(optionsState).map((key) => (
            <MenuItem value={key}>{key}</MenuItem>
          
            ))}
          </Select>
        </FormControl>
              </Grid>

              <Grid item xs={6}>
              <p>Delivery To</p>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Delivery To</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={stateTo}
            label="Product Category"
            onChange={stateToChange}
          >
            {Object.keys(optionsState).map((key) => (
            <MenuItem value={key}>{key}</MenuItem>
          
            ))}
          </Select>
        </FormControl>
              </Grid>

              <Grid item xs={8}>
              <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Step Level</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={stepLevel}
        onChange={handleStepLevelChange}
      >
        <FormControlLabel value="Basic" control={<Radio />} label="Basic" />
        <FormControlLabel value="Standard" control={<Radio />} label="Standard" />
        <FormControlLabel value="Advanced" control={<Radio />} label="Advanced" />
        <FormControlLabel value="Premium" control={<Radio />} label="Premium" />
      </RadioGroup>
    </FormControl>
              </Grid>

              {/* <Grid item xs={8}>
                <Item>xs=8</Item>
              </Grid> 

              <Grid item xs={8}>
                <Item>xs=8</Item>
              </Grid> */}
            </Grid>

    
        </Paper>
      </Grid>
    </Grid>

    
        
    </>
  );
}
