import "./App.css";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Services from "./components/Services";
import Footer from "./components/Footer";
import { AnimatePresence } from "framer-motion";
import Calculator from "./components/Calculator";
function App() {
  return (
    <Router>
      <AnimatePresence mode="wait">
      <Navbar />
      <Routes>
        <Route exact path="/" Component={Home} />
        <Route exact path="/about" Component={AboutUs} />
        <Route exact path="/services" Component={Services} />
        <Route exact path="https://wa.me/7000360531?text=I%27m%20interested%20in%20your%20service" Component={ContactUs} />
        <Route exact path="/calculator" Component={Calculator} />
      </Routes>
      <Footer />
      </AnimatePresence>
    </Router>
    
  );
}

export default App;
